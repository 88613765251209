/* eslint-disable */
/**
 * Trust All Scripts
 *
 * This is a dirty little script for iterating over script tags
 * of your Ghost posts and adding them to the document head.
 *
 * This works for any script that then injects content into the page
 * via ids/classnames etc.
 *
 */
var trustAllScripts = function () {
	window.onload = function () {
		var scriptNodes = document.querySelectorAll('.load-external-scripts script');

		for (var i = 0; i < scriptNodes.length; i += 1) {
			var node = scriptNodes[i];
			var s = document.createElement('script');
			s.type = node.type || 'text/javascript';

			if (node.attributes.src) {
				s.src = node.attributes.src.value;
			} else {
				s.innerHTML = node.innerHTML;
			}
			node.remove();
			document.getElementsByTagName('body')[0].appendChild(s);
		}
	}
};

let fullViewportHeader = () => {
	let viewport = document.querySelector('.viewport')

	if (viewport && viewport.classList.contains('full')) {
		document.querySelector('.site-head').classList.add('site-head--transparent');
	}
}

exports.onRouteUpdate = function () {
	trustAllScripts();
	fullViewportHeader();
	document.onload = fullViewportHeader();
};
